<template>
  <div>
    <VueApexCharts
      ref="chartComponent"
      type="area"
      :options="chartOptions"
      :series="translatedSeries"
      height="350"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    series: { type: Array, default: () => [] },
  },
  data() {
    return {
      chartOptions: this.getChartOptions(),
    };
  },
  computed: {
    translatedCategories() {
      return [
        this.$t("Monday"),
        this.$t("Tuesday"),
        this.$t("Wednesday"),
        this.$t("Thursday"),
        this.$t("Friday"),
        this.$t("Saturday"),
        this.$t("Sunday"),
      ];
    },
    translatedSeries() {
      return this.series.map((serie) => ({
        ...serie,
        name: this.$t(serie.name),
      }));
    },
  },
  watch: {
    translatedCategories: {
      immediate: true,
      handler() {
        this.updateChartOptions();
      },
    },
    "$i18n.locale": {
      immediate: true,
      handler() {
        this.updateChartTranslations();
      },
    },
  },
  methods: {
    getChartOptions() {
      return {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            tools: {
              download: true,
            },
          },
          events: {
            mounted: () => {
              this.updateToolbarTranslations();
            },
            updated: () => {
              this.updateToolbarTranslations();
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: this.translatedCategories,
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: (value) => value,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
          y: {
            formatter: (value) => `${value}`,
          },
        },
      };
    },
    updateChartOptions() {
      if (this.$refs.chartComponent) {
        this.$refs.chartComponent.chart.updateOptions({
          xaxis: {
            categories: this.translatedCategories,
          },
        });
      }
      this.updateToolbarTranslations();
    },
    updateChartTranslations() {
      this.updateChartOptions();
    },
    updateToolbarTranslations() {
      const toolbarItems = document.querySelectorAll(".apexcharts-menu-item");
      toolbarItems.forEach((item) => {
        if (item.classList.contains("exportSVG")) {
          item.innerText = this.$t("Download SVG");
        } else if (item.classList.contains("exportPNG")) {
          item.innerText = this.$t("Download PNG");
        } else if (item.classList.contains("exportCSV")) {
          item.innerText = this.$t("Download CSV");
        }
      });
    },
  },
  mounted() {
    this.updateChartTranslations();
  },
};
</script>