<template>
  <div class="auth-wrapper auth-v2">
    <ParticleAnimation />

    <div class="auth-left" ref="authParentBox">
      <div class="auth-box" ref="authChildBox">
        <b-link class="">
          <img
            src="@/assets/images/svg/logo.svg"
            style="width: 270px; padding-top: 8px"
          />
        </b-link>
        <img src="@/assets/images/svg/teethlogin.png" class="teeth" alt="" />
        <img src="@/assets/images/svg/vector.png" class="vectors" alt="" />
        <p>
          {{ $t("Efficiency & Quality") }} -<br />{{
            $t("let AI revolutionize your work")
          }}!
        </p>
      </div>
    </div>
    <div class="auth-right">
      <div class="auth-detail">
        <div class="auth-detail-logo">
          <h2>{{ $t("Welcome") }}</h2>
          <!-- <h1><span>DENTAL</span>TWIN</h1> -->
          <!-- <img
            src="@/assets/images/svg/logoDark.svg"
            class="light"
            style="width: 270px; padding-top: 8px"
          />
          <img
            src="@/assets/images/svg/logo.svg"
            class="dark"
            style="width: 270px; padding-top: 8px"
          /> -->
        </div>
        <p>
          {{
            $t(
              "Please Sign up to your account and start a new journey into dentistry 4.0"
            )
          }}
        </p>

        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2">
            <!-- username -->
            <b-form-group :label="$t('Username')" label-for="register-username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="register-username"
                  v-model="username"
                  name="register-username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="johndoe"
                />
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group :label="$t('Email')" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="register-email"
                  v-model="userEmail"
                  name="register-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label-for="register-password" :label="$t('Password')">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t("I agree to") }}
                <b-link>{{ $t("privacy policy & terms") }}</b-link>
              </b-form-checkbox>
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="validationForm"
            >
              {{ $t("Sign up") }}
            </b-button>
          </b-form>
        </validation-observer>
        <div class="create-acc">
          {{ $t("Already have a account?") }}
          <b-link :to="{ name: 'login' }">{{ $t("Login here") }}</b-link>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ParticleAnimation from "@/components/ParticleAnimation.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    ParticleAnimation,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      username: "",
      userEmail: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    setWidth() {
      const parentWidth = this.$refs.authParentBox.offsetWidth;
      const parentheight = this.$refs.authParentBox.offsetHeight;
      this.$refs.authChildBox.style.width = `${parentWidth}px`;
      this.$refs.authChildBox.style.height = `${parentheight}px`;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
