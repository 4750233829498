<template>
  <div class="page-header">
    <div class="page-header-left">
      <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
    </div>
    <div class="page-header-right">
      <slot name="filters"></slot>
      <slot name="buttons"></slot>
      <slot name="action"></slot>
      <router-link
        v-if="
          optionalItems.createBtn1.show &&
          (optionalItems.createBtn1.permission
            ? $can(optionalItems.createBtn1.permission)
            : $can(`${$route.meta.permission}.create`))
        "
        :to="optionalItems.createBtn1.path"
        class="btn btn-primary d-flex align-items-center gap-1"
      >
        <feather-icon :icon="optionalItems.createBtn1.icon" size="12" />
        <span>{{ $t(optionalItems.createBtn1.text) }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { BBreadcrumb } from "bootstrap-vue";

export default {
  components: {
    BBreadcrumb,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    optionalItems: {
      type: Object,
      default: () => ({
        createBtn1: {
          show: false,
          text: "",
          icon: "",
          path: "/",
          permission: "",
        },
      }),
    },
  },
};
</script>
