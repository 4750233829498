<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="p-10">
        <b-col lg="12">
          <validation-provider
            #default="{ errors }"
            name="Organizational Unit"
            rules="required"
          >
            <TextInput
              :label="$t('Organizational Unit')"
              :type="'text'"
              v-model="form.unit"
              :placeholder="''"
              :required="true"
            />
            <small class="text-danger">{{ $t(errors[0]) }}</small>
          </validation-provider>
        </b-col>
        <b-col lg="12">
          <TextInput
            :label="$t('Street')"
            :type="'text'"
            v-model="form.street"
            :placeholder="''"
          />
        </b-col>
        <b-col lg="12">
          <TextInput
            :label="$t('Additional Information')"
            :type="'text'"
            v-model="form.additionalInformation"
            :placeholder="''"
          />
        </b-col>
        <b-col lg="12">
          <div class="row">
            <div class="col-3">
              <TextInput
                :label="$t('Zip Code')"
                :type="'text'"
                v-model="form.zipCode"
                :placeholder="''"
              />
            </div>
            <div class="col-9">
              <TextInput
                :label="$t('City')"
                :type="'text'"
                v-model="form.city"
                :placeholder="''"
              />
            </div>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="form-group">
            <label class="form-label input-label" for="country">{{
              $t("Country")
            }}</label>
            <multi-select
              :multiple="false"
              label="name"
              track-by="name"
              :options="countries"
              v-model="form.country"
              :placeholder="$t('Select Option')"
            />
          </div>
        </b-col>
        <b-col lg="6">
          <div class="form-group">
            <label class="form-label input-label" for="address_for_invoices">{{
              $t("Address for Invoices")
            }}</label>
            <multi-select
              :multiple="false"
              label="name"
              track-by="name"
              :options="[]"
              :placeholder="$t('Select Option')"
            />
          </div>
        </b-col>
        <b-col lg="6">
          <TextInput
            :label="$t('Contact Person')"
            :type="'text'"
            :placeholder="''"
            v-model="form.contactPerson"
          />
        </b-col>
        <b-col lg="6">
          <TextInput
            :label="$t('E-Mail Contact Person')"
            :type="'text'"
            v-model="form.contactPersonEmail"
            :placeholder="''"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        variant="primary"
        @click="updateUnit"
        class="d-flex align-items-center gap-1"
      >
        <feather-icon icon="PlusIcon" size="12" />
        <span>{{ $t("Update Unit") }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import MultiSelect from "vue-multiselect";
import countries from "@/assets/data/countries.json";
import TextInput from "@/components/TextInput.vue";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
  emits: ["closeEditModal"],
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  components: {
    MultiSelect,
    TextInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      countries,
      form: {
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      },
    };
  },
  computed: {
    ...mapGetters(["showLoader"]),
  },
  mounted() {
    this.form = { ...this.data };
    this.form.country = this.countries.find(
      (country) => country.name === this.form.country
    );
  },
  methods: {
    async updateUnit() {
      try {
        this.$refs.simpleRules.validate().then(async (success) => {
          if (success) {
            this.$store.commit("showLoader", true);
            await this.$store.dispatch("units/update", {
              id: this.form.id,
              data: {
                ...this.form,
                country: this.form.country?.name ?? "",
              },
            });
            this.resetForm();
            this.$emit("closeEditModal");
            this.$store.commit("showLoader", false);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    resetForm() {
      this.form = {
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      };
    },
  },
};
</script>
