<template>
  <div>
    <div v-if="PaymentInProcess" class="process-page">
      <div class="icon-wrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 100 100"
          width="100"
          height="100"
          preserveAspectRatio="xMidYMid meet"
          class="icon"
        >
          <defs>
            <clipPath id="__lottie_element_294">
              <rect width="100" height="100" x="0" y="0"></rect>
            </clipPath>
          </defs>
          <g clip-path="url(#__lottie_element_294)">
            <g
              transform="matrix(0.9032849073410034,0,0,0.9032849073410034,9.352180480957031,4.83575439453125)"
              opacity="1"
              style="display: block"
            >
              <g opacity="1" transform="matrix(1,0,0,1,5,5)">
                <g opacity="1" transform="matrix(1,0,0,1,40,45)">
                  <path
                    fill="rgb(255,255,255)"
                    fill-opacity="1"
                    d=" M17.5,15 C17.5,15 -17.5,15 -17.5,15 C-20.26099967956543,15 -22.5,12.76099967956543 -22.5,10 C-22.5,10 -22.5,-10 -22.5,-10 C-22.5,-12.76200008392334 -20.26099967956543,-15 -17.5,-15 C-17.5,-15 17.5,-15 17.5,-15 C20.26099967956543,-15 22.5,-12.76200008392334 22.5,-10 C22.5,-10 22.5,10 22.5,10 C22.5,12.76099967956543 20.26099967956543,15 17.5,15z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill-opacity="0"
                    stroke="rgb(60,95,246)"
                    stroke-opacity="1"
                    stroke-width="2"
                    d=" M17.5,15 C17.5,15 -17.5,15 -17.5,15 C-20.26099967956543,15 -22.5,12.76099967956543 -22.5,10 C-22.5,10 -22.5,-10 -22.5,-10 C-22.5,-12.76200008392334 -20.26099967956543,-15 -17.5,-15 C-17.5,-15 17.5,-15 17.5,-15 C20.26099967956543,-15 22.5,-12.76200008392334 22.5,-10 C22.5,-10 22.5,10 22.5,10 C22.5,12.76099967956543 20.26099967956543,15 17.5,15z"
                  ></path>
                </g>
                <g opacity="1" transform="matrix(1,0,0,1,40,37.5)">
                  <path
                    fill="rgb(197,210,255)"
                    fill-opacity="1"
                    d=" M22.5,2.5 C22.5,2.5 -22.5,2.5 -22.5,2.5 C-22.5,2.5 -22.5,-2.5 -22.5,-2.5 C-22.5,-2.5 22.5,-2.5 22.5,-2.5 C22.5,-2.5 22.5,2.5 22.5,2.5z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill-opacity="0"
                    stroke="rgb(60,95,246)"
                    stroke-opacity="1"
                    stroke-width="2"
                    d=" M22.5,2.5 C22.5,2.5 -22.5,2.5 -22.5,2.5 C-22.5,2.5 -22.5,-2.5 -22.5,-2.5 C-22.5,-2.5 22.5,-2.5 22.5,-2.5 C22.5,-2.5 22.5,2.5 22.5,2.5z"
                  ></path>
                </g>
                <g opacity="1" transform="matrix(1,0,0,1,27.5,52.5)">
                  <path
                    fill="rgb(197,210,255)"
                    fill-opacity="1"
                    d=" M2.5,2.5 C2.5,2.5 -2.5,2.5 -2.5,2.5 C-3.88100004196167,2.5 -5,1.38100004196167 -5,0 C-5,-1.38100004196167 -3.88100004196167,-2.5 -2.5,-2.5 C-2.5,-2.5 2.5,-2.5 2.5,-2.5 C3.88100004196167,-2.5 5,-1.38100004196167 5,0 C5,1.38100004196167 3.88100004196167,2.5 2.5,2.5z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 100 100"
          width="100"
          height="100"
          preserveAspectRatio="xMidYMid meet"
          class="rotate"
        >
          <defs>
            <clipPath id="__lottie_element_294">
              <rect width="100" height="100" x="0" y="0"></rect>
            </clipPath>
          </defs>
          <g clip-path="url(#__lottie_element_294)">
            <g
              transform="matrix(0.41152340173721313,-0.9113991856575012,0.9113991856575012,0.41152340173721313,-14.316360473632812,70.58390808105469)"
              opacity="1"
              style="display: block"
            >
              <g class="" opacity="1" transform="matrix(1,0,0,1,5,5)">
                <g opacity="1" transform="matrix(1,0,0,1,40,45)" class="">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill-opacity="0"
                    stroke="rgb(60,95,246)"
                    stroke-opacity="1"
                    stroke-width="2"
                    d=" M20.50200080871582,-28.3700008392334 C29.285999298095703,-22.01099967956543 35,-11.67300033569336 35,0 C35,19.329999923706055 19.329999923706055,35 0,35 C-19.33099937438965,35 -35,19.329999923706055 -35,0 C-35,-19.329999923706055 -19.33099937438965,-35 0,-35"
                  ></path>
                </g>
                <g
                  opacity="1"
                  transform="matrix(1,0,0,1,38.22700119018555,10)"
                  class=""
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill-opacity="0"
                    stroke="rgb(60,95,246)"
                    stroke-opacity="1"
                    stroke-width="2"
                    d=" M-2.5,-5 C-2.5,-5 2.5,0 2.5,0 C2.5,0 -2.5,5 -2.5,5"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div v-if="countdown > 0">
        <h1>
          {{
            $t(
              "Your payment is being processed and should be completed in approximately"
            )
          }}
        </h1>
        <h2>
          <strong>{{ countdown }}s</strong>
        </h2>
        <p>{{ $t("Please do not close this window") }}!</p>
      </div>
      <h1 v-else>
        {{ $t("Still waiting... Should be done in a few seconds") }}!
      </h1>
    </div>
    <div v-else class="success-page">
      <img src="@/assets/images/svg/mobile-payment.png" alt="" />
      <h1>{{ $t("Payment Successful") }}!</h1>
      <p>
        {{
          $t(
            "Thank you for your purchase! Your payment has been processed successfully."
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      intervalId: null,
      sessionId: null, // Initialize sessionId
      PaymentInProcess: false,
      countdownIntervalId: null,
      countdown: 30,
    };
  },
  created() {
    this.PaymentInProcess = true;
    this.startCountdown();
    this.stripeSession();
    this.intervalId = setInterval(async () => {
      await this.stripeSession();
    }, 10000);
  },
  methods: {
    startCountdown() {
      this.countdownIntervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.countdownIntervalId);
        }
      }, 1000);
    },
    async stripeSession() {
      this.sessionId = this.$route.query.session_id;
      if (this.sessionId) {
        await this.$store
          .dispatch("packages/stripeSession", {
            sessionId: this.sessionId,
          })
          .then((res) => {
            if (res.status === 200) {
              this.PaymentInProcess = false;
              clearInterval(this.intervalId);
              clearInterval(this.countdownIntervalId);
              this.$store.dispatch("customers/getCredits");
            }
          })
          .finally(() => {});
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.countdownIntervalId);
  },
};
</script>

<style scoped lang="scss">
.success-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding: 100px 0;
  img {
    width: 300px;
  }
}

.btn {
  margin: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff; /* Bootstrap primary color */
}

.btn-secondary {
  background-color: #6c757d; /* Bootstrap secondary color */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped lang="scss">
.process-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding: 100px 200px;
  img {
    width: 300px;
  }
  .icon-wrap {
    position: relative;
    svg {
      width: 300px;
      height: 300px;
    }
    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.rotate {
  animation: animName 2s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
