export default {
  namespaced: true,
  state: {
    permissions: [],
    permissionsGlobal: [],
    groupings: [],
    count: 0,
  },
  getters: {
    groupings: (state) => state.groupings,
    permissions: (state) => state.permissions,
    permissionsGlobal: (state) => state.permissionsGlobal,
    count: (state) => state.count,
  },
  mutations: {
    permissions: (state, payload) => (state.permissions = payload),
    permissionsGlobal: (state, payload) => (state.permissionsGlobal = payload),
    count: (state, payload) => (state.count = payload),

    groupings: (state, payload) => (state.groupings = payload),
  },
  actions: {
    permissionsGlobal({ commit }, queryParams) {
      return this.$authApiService
        .get("/list-permissions", {
          params: queryParams,
        })
        .then((res) => {
          commit("permissionsGlobal", [...res?.data?.data] ?? []);
        });
    },
    list({ commit }, queryParams) {
      return this.$authApiService
        .get("/list-permissions", {
          params: queryParams,
        })
        .then((res) => {
          commit("permissions", res?.data?.data ?? []);
          commit("permissionsGlobal", [...res?.data?.data] ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$authApiService.post("/create-permission", payload);
    },
    show({}, id) {
      return this.$authApiService.get(`/list-permission-by-id?id=${id}`);
    },
    update({}, data) {
      return this.$authApiService.post(`/update-permission`, data);
    },
    destroy({}, payload) {
      return this.$authApiService.post(`/delete-permission`, payload);
    },
    listGroupings({ commit }, queryParams) {
      return this.$authApiService
        .get("/list-groupings", {
          params: queryParams,
        })
        .then((res) => {
          commit("groupings", res?.data?.data ?? []);
          return res;
        });
    },
  },
};
