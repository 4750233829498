<template>
  <div class="news-page">
    <PageHeader :items="items" />
    <div class="masonry-container">
      <div v-for="(item, index) in news" :key="index" class="news-card">
        <div class="news-card-body">
          <h4 class="news-card-title">{{ item.subject }}</h4>
          <div v-if="item.image" class="news-image mt-1 mb-2">
            <img :src="item.image.url" alt="" />
          </div>
          <div class="news-card-description truncated-text">
            <p v-html="truncateText(item.description, 300)"></p>
          </div>
          <span
            v-if="item.description"
            class="news-read-more"
            @click="openFullNews(item)"
            >read more</span
          >
        </div>
        <div class="news-card-footer">
          <div class="news-card-details">
            <p class="news-posted-time">{{ item.createdAt }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-modal" v-if="showNewsDetails">
      <div class="modal-overlay"></div>
      <div class="modal-content">
        <div class="modal-md modal-bg">
          <div class="modal-header">
            <div class="d-flex align-items-center">
              <h3>News</h3>
            </div>
            <div class="x-icon" @click="closeNews">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="modal-body news-detail-text">
            <h3 class="card-title">
              {{ newsModalData.subject }}
            </h3>
            <div v-if="newsModalData.image" class="news-image">
              <img :src="newsModalData.image.url" alt="" />
            </div>
            <p class="description-text" v-html="newsModalData.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt();
export default {
  components: {
    PageHeader,
    ElementIcon,
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.language = this.localeToLanguageMap[newVal];
      this.getNews();
    },
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("News"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      localeToLanguageMap: {
        en: "english",
        fr: "french",
        de: "german",
        es: "spanish",
      },
      news: "",
      language: "",
      showNewsDetails: false,
      newsModalData: "",
    };
  },
  mounted() {
    const currentLocale = this.$i18n.locale;

    // Set the language based on the locale
    this.language = this.localeToLanguageMap[currentLocale]; // Fallback to 'Unknown' if the locale is not mapped

    this.getNews();
  },
  methods: {
    truncateText(text, maxLength) {
      if (!!text) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + "...";
        } else {
          return text;
        }
      }
    },
    openFullNews(data) {
      this.showNewsDetails = true;
      this.newsModalData = data;
    },
    closeNews() {
      this.showNewsDetails = false;
    },
    async getNews() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("news/list", {
          language: this.language,
          sortBy: "createdAt",
          sortOrder: "desc",
        });
        this.news = response?.data?.data;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
  },
};
</script>

<style lang="scss">
.news-image {
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}
</style>
