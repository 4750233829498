<template>
  <div class="account-page">
    <PageHeader :items="items" />
    <div class="card">
      <div class="card-header">
        <h3>{{ $t("Profile") }}</h3>
      </div>
      <div class="card-body">
        <b-row>
          <b-col lg="4" class="mb-2 mb-lg-0">
            <div class="profile-card">
              <div class="upload-img">
                <img
                  v-if="user && user.profile_image"
                  :src="user.profile_image"
                  alt=""
                />
                <img
                  v-else
                  :src="require('@/assets/images/avatars/undefined.png')"
                  alt=""
                />
                <input
                  @change="handleFileChange"
                  type="file"
                  ref="fileInput"
                  style="display: none"
                />
                <div class="delete-acc" @click="$refs.fileInput.click()">
                  <ElementIcon v-bind:icon="'camimageIcon'" />
                </div>
              </div>
              <!-- <h2>Mubashar <span>Chishtee</span></h2> -->
              <!-- <div class="desination">UI/UX Designer</div>
                <div class="location">
                  <ElementIcon v-bind:icon="'navIcon'" />
                  <p>DocsHero GmbH Lorenzstraße 29 76135 Karlsruhe</p>
                </div> -->
              <!-- <div class="divider"></div>
                <div class="delete-acc">{{ $t("Delete Account") }}</div> -->
            </div>
          </b-col>
          <b-col lg="8">
            <b-row class="align-items-end">
              <b-col lg="6">
                <TextInput
                  :label="$t('First Name')"
                  :type="'text'"
                  v-model="userUnit.firstName"
                  :placeholder="''"
                />
              </b-col>
              <b-col lg="6">
                <TextInput
                  :label="$t('Last Name')"
                  :type="'text'"
                  v-model="userUnit.lastName"
                  :placeholder="''"
                />
              </b-col>
              <b-col lg="12">
                <div class="form-group">
                  <label
                    class="form-label input-label"
                    for="address_for_invoices"
                    >{{ $t("Role") }}</label
                  >
                  <multi-select
                    :multiple="false"
                    label="title"
                    track-by="id"
                    :options="roles"
                    v-model="userUnit.role"
                    :placeholder="$t('Select Option')"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="form-label input-label" for="location">{{
                    $t("Location")
                  }}</label>
                  <multi-select
                    :multiple="false"
                    label="name"
                    track-by="name"
                    :options="countries"
                    v-model="userUnit.location"
                    :placeholder="$t('Select Option')"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group" v-if="userUnit.location">
                  <label
                    class="form-label input-label"
                    for="storage_location"
                    >{{ $t("Storage Location") }}</label
                  >
                  <div class="form-control">
                    <p v-if="userUnit.location">
                      {{ userUnit.location.name }}
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col lg="2">
                <div class="form-group">
                  <label
                    class="form-label input-label"
                    for="address_for_invoices"
                    >{{ $t("Mobile phone number") }}</label
                  >
                  <multi-select
                    :multiple="false"
                    label="dial_code"
                    track-by="dial_code"
                    :options="countries"
                    v-model="userUnit.countryCode"
                    :placeholder="$t('Select Option')"
                  />
                </div>
              </b-col>
              <b-col lg="5">
                <div class="form-group">
                  <label
                    class="form-label input-label"
                    for="address_for_invoices"
                  >
                  </label>
                  <TextInput
                    :type="'text'"
                    v-model="userUnit.mobileNo"
                    :placeholder="''"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <TextInput
              :label="$t('E-mail')"
              :type="'text'"
              v-model="form.mail"
              :placeholder="''"
            />
          </b-col>
          <b-col lg="6">
            <div class="form-group">
              <label
                class="form-label input-label"
                for="address_for_invoices"
                >{{ $t("Color Scheme") }}</label
              >
              <multi-select
                :multiple="false"
                label="label"
                track-by="name"
                :options="colorSchemeOptions"
                v-model="userUnit.colorScheme"
                :placeholder="$t('Select Option')"
              />
            </div>
          </b-col>
          <b-col lg="6">
            <div class="d-flex align-items-center justify-content-between">
              <h3>{{ $t("Enable 2-step Verification") }}n</h3>
              <div class="switch-group">
                <input type="checkbox" class="switch-input" id="verification" />
                <label for="verification" class="switch-label"></label>
              </div>
            </div>
          </b-col>
          <b-col lg="12">
            <div class="checkbox-group mt-2 mb-1">
              <input
                type="checkbox"
                class="checkbox-input"
                id="notify"
                v-model="userUnit.isNotifyMe"
              />
              <label for="notify" class="checkbox-label">{{
                $t("Notify me, when free storage is less than 15% and 5%.")
              }}</label>
            </div>
          </b-col>
          <b-col lg="12">
            <div class="checkbox-group">
              <input
                type="checkbox"
                class="checkbox-input"
                id="recieve"
                v-model="userUnit.isAgree"
              />
              <label for="recieve" class="checkbox-label">{{
                $t(
                  "I agree to recieve Marketing and Promotion materials (optional)."
                )
              }}</label>
            </div>
          </b-col>
          <b-col cols="12" class="my-2">
            <h3>{{ $t("Create a new password") }}</h3>
          </b-col>
          <b-col lg="6">
            <TextInput
              :label="$t('New Password')"
              :type="'password'"
              v-model="form.password"
            />
          </b-col>
          <b-col lg="6">
            <TextInput
              :label="$t('Re Enter new Password')"
              :type="'password'"
              v-model="form.confirmPassword"
            />
          </b-col>
        </b-row>
        <div class="d-flex align-items-center justify-content-end">
          <button @click="update" class="btn btn-secondary">
            <span>{{ $t("Update Profile") }}</span>
          </button>
        </div>
        <div class="e-card mt-2">
          <div class="e-card-header pb-0">
            <h3>{{ $t("Address") }}</h3>
          </div>
          <div class="e-card-body">
            <b-row>
              <b-col lg="6">
                <TextInput
                  :isReadonly="true"
                  :label="$t('Company')"
                  :type="'text'"
                  v-model="unit.company"
                  :placeholder="''"
                />
              </b-col>
              <b-col lg="6">
                <TextInput
                  :isReadonly="true"
                  :label="$t('Organizational Unit')"
                  :type="'text'"
                  v-model="unit.unit"
                  :placeholder="''"
                />
              </b-col>
              <b-col lg="6">
                <TextInput
                  :isReadonly="true"
                  :label="$t('Street')"
                  :type="'text'"
                  v-model="unit.street"
                  :placeholder="''"
                />
              </b-col>
              <b-col lg="6">
                <TextInput
                  :isReadonly="true"
                  :label="$t('Additional Information')"
                  :type="'text'"
                  v-model="unit.additionalInformation"
                  :placeholder="''"
                />
              </b-col>
              <b-col lg="6">
                <div class="row">
                  <div class="col-3">
                    <TextInput
                      :isReadonly="true"
                      :label="$t('Zip Code')"
                      :type="'text'"
                      v-model="unit.zipCode"
                      :placeholder="''"
                    />
                  </div>
                  <div class="col-9">
                    <TextInput
                      :isReadonly="true"
                      :label="$t('City')"
                      :type="'text'"
                      v-model="unit.city"
                      :placeholder="''"
                    />
                  </div>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label class="form-label input-label" for="country">{{
                    $t("Country")
                  }}</label>
                  <multi-select
                    disabled
                    :multiple="false"
                    label="name"
                    track-by="name"
                    :options="countries"
                    v-model="unit.country"
                    :placeholder="$t('Select Option')"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div class="form-group">
                  <label
                    class="form-label input-label"
                    for="address_for_invoices"
                    >{{ $t("Address for Invoices") }}</label
                  >
                  <multi-select
                    disabled
                    :multiple="false"
                    label="name"
                    track-by="name"
                    :options="[]"
                    :placeholder="$t('Select Option')"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <TextInput
                  :isReadonly="true"
                  :label="$t('Contact Person')"
                  :type="'text'"
                  :placeholder="''"
                  v-model="unit.contactPerson"
                />
              </b-col>
              <b-col lg="6">
                <TextInput
                  :isReadonly="true"
                  :label="$t('E-Mail Contact Person')"
                  :type="'text'"
                  v-model="unit.contactPersonEmail"
                  :placeholder="''"
                />
              </b-col>
            </b-row>
            <!-- <div class="d-flex align-items-center justify-content-end mt-2">
              <button
                :disabled="!isUnit"
                @click="detachUnit()"
                class="btn btn-secondary mr-1"
              >
                <span>{{ $t("Delete Address") }}</span>
              </button>
              <button @click="storeUnit()" class="btn btn-primary">
                <span>{{ $t("Save Address") }}</span>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from "bootstrap-vue";
import Card from "@/components/elements/Card.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import TextInput from "@/components/TextInput.vue";
import NotificationService from "../../../services/notification.service";
import { mapGetters } from "vuex";
import countries from "@/assets/data/countries.json";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
export default {
  components: {
    Card,
    BLink,
    BRow,
    BCol,
    ElementIcon,
    TextInput,
    MultiSelectInput,
    MultiSelect,
    PageHeader,
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("roles", ["roles"]),
    ...mapGetters(["showLoader"]),

    colorSchemeOptions() {
      return [
        {
          label: this.$t("Dark Mode - Dental Twin style"),
          name: "dark-mode",
        },
        {
          label: this.$t("Light Mode - Dental Twin style"),
          name: "light-mode",
        },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("My Info"),
          to: "/account",
        },
        {
          text: this.$t("Account"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      selectedRows: [], // Array to hold selected row IDs
      countries,
      totalRecords: 0,
      pageLength: 10,
      isUnit: false,
      page: 1,
      userUnit: {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        location: null,
        countryCode: "",
        colorScheme: null,
        isAgree: false,
        isNotifyMe: false,
      },
      form: {
        mail: "",
        password: "",
        confirmPassword: "",
      },
      unit: {
        company: "",
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      },
      rows: [],
      searchTerm: "",
    };
  },
  async beforeCreate() {
    try {
      this.$store.commit("showLoader", true);
      this.$store
        .dispatch("auth/show", {
          id: localStorage.getItem("user_id"),
        })
        .then((res) => {
          this.form.mail = res?.data.email;
        });
      await this.$store.dispatch("roles/list", {
        limit_start: 0,
        limit_count: 100,
      });
      this.showUnitUserByUserId();
      this.showUnitByUserId();
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async handleFileChange(event) {
      try {
        const file = event.target.files[0];
        await this.setProfilePicture(file, this.user);
        this.$store.dispatch("auth/show", { id: this.user.id }).then((res) => {
          this.$store.commit("auth/set_user", res?.data ?? {});
        });
      } catch (e) {
        console.log(e);
      }
    },
    // async detachUnit() {
    //   await this.$store
    //     .dispatch("units/detachUnit", {
    //       userId: this.user.id,
    //     })
    //     .then((res) => {
    //       this.resetForm();
    //       this.isUnit = false;
    //     });
    // },
    async storeUnitUser() {
      await this.$store.dispatch("unitUsers/create", {
        ...this.userUnit,
        roleId: this.userUnit?.role?.id ?? "",
        roleName: this.userUnit?.role?.title ?? "",
        userId: this.user.id,
        location: this.userUnit.location?.name ?? "",
        countryCode: this.userUnit?.countryCode?.dial_code ?? "",
        colorScheme: this.userUnit?.colorScheme?.name ?? "",
      });
    },
    resetForm() {
      this.unit = {
        company: "",
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      };
    },
    // async storeUnit() {
    //   await this.$store
    //     .dispatch("units/create", {
    //       ...this.unit,
    //       country: this.unit.country?.name ?? "",
    //       userId: this.user.id,
    //     })
    //     .then((res) => {
    //       this.isUnit = true;
    //     });
    // },
    async showUnitUserByUserId() {
      this.$store
        .dispatch("unitUsers/showByUserId", {
          userId: localStorage.getItem("user_id"),
        })
        .then((res) => {
          const response = res?.data?.data;
          this.userUnit.firstName = response?.firstName;
          this.userUnit.lastName = response?.lastName;
          this.userUnit.mobileNo = response?.mobileNo;
          this.userUnit.isAgree = response?.isAgree;
          this.userUnit.isNotifyMe = response?.isNotifyMe;

          if (response?.roleId) {
            this.$store
              .dispatch("roles/show", {
                id: response?.roleId,
              })
              .then((res) => {
                this.userUnit.role = res?.data;
              });
          }
          if (response?.location) {
            this.userUnit.location = {
              name: response?.location,
            };
          }

          if (response?.countryCode) {
            this.userUnit.countryCode = {
              dial_code: response?.countryCode,
            };
          }

          if (response?.colorScheme) {
            this.userUnit.colorScheme = this.colorSchemeOptions.find(
              (option) => option.name === response.colorScheme
            );
          }
        });
    },
    async showUnitByUserId() {
      this.$store
        .dispatch("units/showByUserId", {
          userId: localStorage.getItem("user_id"),
        })
        .then((res) => {
          this.isUnit = true;
          const response = res?.data?.unit;
          this.unit.company = res?.data?.company;
          this.unit.unit = response?.unit;
          this.unit.street = response?.street;
          this.unit.additionalInformation = response?.additionalInformation;
          this.unit.zipCode = response?.zipCode;
          this.unit.city = response?.city;
          this.unit.contactPerson = response?.contactPerson;
          this.unit.contactPersonEmail = response?.contactPersonEmail;

          if (response?.country) {
            this.unit.country = {
              name: response?.country,
            };
          }
        });
    },
    async update() {
      try {
        if (this.form.password.length || this.form.confirmPassword.length) {
          if (this.form.password !== this.form.confirmPassword) {
            NotificationService.showError(
              "Password and confirm password do not match"
            );
            return;
          }
        }
        if (!this.form.password.length) {
          delete this.form["password"];
          delete this.form["confirmPassword"];
        }
        const payload = {
          ...this.form,
          id: this.user.id,
        };

        this.$store.commit("isLoading", true);
        await this.$store.dispatch("auth/update", payload);
        this.$store
          .dispatch("auth/show", {
            id: this.user.id,
          })
          .then((res) => {
            this.$store.commit("auth/set_user", res?.data ?? {});
            this.storeUnitUser();
          });
      } catch (e) {}
    },
    setProfilePicture(file, user) {
      return new Promise((resolve, reject) => {
        // convert to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = (err) => {
          console.log(err);
          reject(err);
        };
        reader.onloadend = async () => {
          let base64 = reader.result;
          // hit the auth update API to update the profile_image
          this.$store
            .dispatch("auth/update", {
              profile_image: base64,
              id: this.user.id,
            })
            .then(() => {
              resolve(base64);
            })
            .catch((err) => reject(err));
        };
      });
    },
  },
};
</script>

<style></style>
