<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <div class="table-responsive api-keys-table">
      <!-- table -->
      <vue-good-table
        styleClass="vgt-table striped"
        :columns="columns"
        :fixed-header="false"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          trigger: 'enter',
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          emptyTableMessage: 'No records found',
        }"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'costs'">{{
            $formatter(props.row.costs)
          }}</span>
          <span v-else-if="props.column.field === 'created_at'">{{
            $dateFormatter(props.row.created_at)
          }}</span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageHeader,
    Multiselect,
  },

  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t(""),
          icon: "PlusIcon",
          path: "",
          permission: "",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Payment History"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Company Name"),
          field: "company_name",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Costs"),
          field: "costs",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Credits"),
          field: "credits",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Session ID"),
          field: "session_id",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Status"),
          field: "status",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Created Date"),
          field: "created_at",
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      rows: [],
      searchTerm: "",
    };
  },

  async created() {
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch(
          "packages/getPaymentHistory",
          {
            page: this.page,
            ...this.form,
            perPage: this.pageLength,
          }
        );
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.count;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store.dispatch("", id).finally(() => {
            this.loadItems();
          });
        }
      });
    },
  },
};
</script>
