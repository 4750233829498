export default {
  namespaced: true,
  state: {
    packages: [],
  },
  getters: {
    packages: (state) => state.packages,
  },
  mutations: {
    packages: (state, payload) => (state.packages = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/get-packages", {
          params: queryParams,
        })
        .then((res) => {
          commit("packages", res?.data?.data ?? []);
          return res;
        });
    },
    stripeCheckout({}, payload) {
      return this.$apiService.post("/stripe-checkout", payload);
    },
    stripeSession({}, payload) {
      return this.$apiService.post("/stripe-session", payload);
    },
    getPaymentHistory({}, queryParams) {
      return this.$apiService.get(`/get-payment-history`, {
        params: queryParams,
      });
    },
  },
};
